import Axios from "axios";
import Cookies from "js-cookie";
import { LocalCookies } from "features/global/data/localCookies";
import moment from "moment-timezone";
import { Socket } from "./socket";
import { EnvType } from './socket'

export const getToken = () => Cookies.get("token");

// running service
export const hostLocal = "localhost:3030"
export const hostDev = "dev-frontend.youapp.ai"
export const hostUAT = "frontadmin-uat.youapp.ai:88"
export const hostPrePROD = "testflight.youapp.ai:88"
export const hostPROD = "fe-admin.youapp.ai"
export const pinggirJurang = "ecd7-103-125-48-74.ngrok-free.app"

// API BE
const urls = {
  // local: "https://uat-admin.youapp.ai/",
  // local: "https://dev-admin.youapp.ai/",
  // pinggirJurang: "https://e454-103-125-48-74.ngrok-free.app",
  local: "http://localhost:3002/",
  dev: process.env.REACT_APP_API_DEV || "https://dev-admin.youapp.ai/",
  uat: process.env.REACT_APP_API_UAT || "https://uat-admin.youapp.ai/",
  preProd: "https://testflight.youapp.ai:3002/",
  prod: process.env.REACT_APP_API_PROD || "https://admin3321456.youapp.ai/"
}

const appUrls = {
  // local: "https://api-uat.youapp.ai/",
  // local: "https://api-dev.youapp.ai/api/",
  // pinggirJurang: "https://e454-103-125-48-74.ngrok-free.app",
  local: "http://localhost:3000/",
  dev: process.env.REACT_APP_API_APP_DEV || "https://api-dev.youapp.ai/api/",
  uat: process.env.REACT_APP_API_APP_UAT || "https://api-uat.youapp.ai/",
  preProd: "https://testflight.youapp.ai:3000/",
  prod: process.env.REACT_APP_API_APP_PROD || "https://api-release33216789.youapp.ai/"
}



/**
 * @type {EnvType} 
 * 
 * @description
 * - prod 
 * - uat 
 * - dev 
 * - local 
 * - '' // untuk auto
 */
const devEnv = 'local'

export const detectHost = ({ isAppApi = false, getCurrentEnv = false }) => {
  const host = window.location.host

  /**
   * @type {EnvType}
   */
  let currentEnv = "local"
  if (host === hostLocal) {
    currentEnv = "local"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostDev) {
    currentEnv = "dev"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === pinggirJurang) {
    currentEnv = "pinggirJurang"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostUAT) {
    currentEnv = "uat"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostPrePROD) {
    currentEnv = "preProd"
    // console.info(`you running on ${currentEnv}`);
  } else if (host === hostPROD) {
    currentEnv = "prod"
    // console.info(`you running on ${currentEnv}`);
  } else {
    if (window.location.pathname.includes("404")) return
    alert("Host not found!")
    window.location = "/404"
  }

  if (currentEnv === 'local' && devEnv) currentEnv = devEnv

  if (getCurrentEnv) return currentEnv
  return isAppApi ? appUrls[currentEnv] : urls[currentEnv]
  // return isAppApi ? appUrls.dev : urls[currentEnv]
}

const createApiInstance = ({
  isFormData = false,
  isAppApi = false,
} = {
    isFormData: false,
    isAppApi: false,
  }) => {
  const stateApi = detectHost({ isAppApi: isAppApi })
  const apiUrl = stateApi
  const api = Axios.create({
    baseURL: apiUrl,
    headers: {
      Accept: isFormData ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${isAppApi ? Socket.getToken : getToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      "Access-route": window.location.pathname,
    },
  });

  api.interceptors.request.use((config) => {
    const accessToken = isAppApi ? Socket.getToken : getToken();
    config.headers = Object.assign({
      "Platfrom-Timezone": moment.tz.guess(true),
      ...LocalCookies.filterCountry.header(),
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    }, config.headers);

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  api.interceptors.response.use((response) => response, (error) => {
    const status = error.response?.status || 500;
    if (status === 401 && window.location.pathname !== "/login") {
      if (isAppApi) {
        alert('please update admin token!')
      } else {
        Cookies.remove("token");
        window.location = "/login"
      }
    }

    return Promise.reject(error);
  });

  return api;
};

export default createApiInstance;