import {
  ContentManagementAuthorize,
  DashboardAuthorize,
  FinanceManagementAuthorize,
  GlobalSettingAuthorize,
  LogsAuthorize,
  MasterAdminAuthorize,
  MatchContentManagementAuthorize,
  MeetsContentManagementAuthorize,
  SelfDiscoveryAuthorize,
  UserManagementAuthorize,
} from "features/global/data/authorizeData";

/**
 *
 * @default route contain all the route by category
 *
 */
export const route = {
  uncategorized: {
    name: "uncategorized",
    route: {
      notFound: {
        path: "*",
        pathFile: "pages/404",
      },
      login: {
        path: "/login",
        pathFile: "pages/Login/login",
      },
      changePasswordOverview: {
        path: "/changePassword/overview",
        autorize: "",
        pathFile: "pages/Login/changePassword",
      },
      WelcomeOverview: {
        path: "/welcome/overview",
        pathFile: "pages/MasterAdmin/Welcome",
      },
      StatisticDashboard: {
        path: "/statistic",
        pathFile: "pages/Statistic",
      },
      ReportedProfileOverview: {
        path: "/reportedprofile/overview",
        pathFile: "pages/account/ReportedProfile",
      },
      ReportedServiceOverview: {
        path: "/reportedservice/overview",
        pathFile: "pages/contentManagement/ReportedService",
      },
      DetailReportedServiceOverview: {
        path: "/reported-content/experience/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedService/index",
      },
      MessageGroupDetailOverview: {
        path: "/messagegroup/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailGroup/DetailMessage",
      },
      ReportedGroupListOverview: {
        path: "/reportedgrouplist/overview",
        pathFile: "pages/contentManagement/ReportedGroup",
      },
      DetailReportedGroupListOverview: {
        path: "/reported-content/group/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedGroup",
      },
      ReportedMessageDetailOverview: {
        path: "/reportedMessage/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedGroup/DetailMessage",
      },
      ReportedEventListOverview: {
        path: "/reportedeventlist/overview",
        pathFile: "pages/contentManagement/ReportedEvent",
      },
      DetailReportedMiniEventDetail: {
        path: "/reported-content/mini-event/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedMiniEvent/index",
      },
      DetailPlaces: {
        path: "/places/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailPlaces",
      },
      EditPlaces: {
        path: "/places/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailPlaces/edit",
      },
      DetailPeople: {
        path: "/people/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailPlaces/detailvisited",
      },
      ReportedPlacesOverview: {
        path: "/reportedplaces/overview",
        pathFile: "pages/contentManagement/ReportedPlaces",
      },
      DetailReportedPlacesOverview: {
        path: "/reported-content/place/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedPlaces",
      },
      AddFeatureEventOverview: {
        path: "/featureevent/addfeatureevent/overview",
        pathFile: "pages/contentManagement/featureEvents",
      },
      AddFeatureComunityOverview: {
        path: "/featurecommunity/addfeaturecommunity/overview",
        pathFile: "pages/contentManagement/featureComunitys",
      },
      CreateCategorySelectionEvents: {
        path: "/categoryselectionevents/create/overview",
        pathFile: "pages/contentManagement/selectorCategoryEvent/create",
        hideCountryNavbar: true,
      },
      CreateCategorySelectionExperience: {
        path: "/categoryselectionexperience/create/overview",
        pathFile: "pages/contentManagement/selectorCategoryExp/create",
        hideCountryNavbar: true,
      },
      CreateCategorySelectionPlace: {
        path: "/categoryselectionplace/create/overview",
        pathFile: "pages/contentManagement/selectorCategoryPlace/create",
      },
      ReportedComunityOverview: {
        path: "/reportedcommunity/overview",
        pathFile: "pages/contentManagement/ReportedComunity",
      },
      DetailReportedComunityOverview: {
        path: "/reported-content/community/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedComunity",
      },
      ArticlesOverview: {
        path: "/articles/overview",
        pathFile: "pages/contentManagement/Articles",
      },
      DetailArticlesOverview: {
        path: "/articles/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailArticle",
      },
      EditArticlesOverview: {
        path: "/articles/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailArticle/edit",
      },
      ReportedArticlesOverview: {
        path: "/reportedarticles/overview",
        pathFile: "pages/contentManagement/ReportedArticles",
      },
      DetailReportedArticlesOverview: {
        path: "/reported-content/article/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedArticles",
      },
      DetailReportedPostComunities: {
        path: "/reported-content/post/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedPostComunity",
      },
      DetailReportedPostMedia: {
        path: "/reported-content/media/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailReportedMediaComunity/index",
      },
      DetailMediaComunity: {
        path: "/media/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailMedia",
      },
      PaymentLinkOverview: {
        path: "/paymentlink/overview",
        pathFile: "pages/Finance/PaymentLink",
      },
      AddPaymentLinkOverview: {
        path: "/paymentlink/addpaymentlink/overview",
        pathFile: "pages/Finance/Payment/create",
      },
      DetailPaymentLinkOverview: {
        path: "/paymentlink/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/Finance/Payment/detail",
      },
      fxRate: {
        path: "/fxRate/overview",
        pathFile: "pages/Setting/FxRate",
        hideCountryNavbar: true
      },
      addFxRate: {
        path: "/fxRate/addFxRate/overview",
        pathFile: "pages/Setting/fxrate/add",
        hideCountryNavbar: true
      },
      editFxRate: {
        path: "/fxRate/editFxRate/:id",
        hideCountryNavbar: true,
        pathFile: "pages/Setting/fxrate/edit",
      },
      createSubcription: {
        path: "/subcription/create",
        pathFile: "pages/subsriptions/manageSubcription",
        hideCountryNavbar: true
      },
      addPlanSettings: {
        path: "/subcription/:id/plan",
        hideCountryNavbar: true,
        pathFile: "pages/subsriptions/plans/form",
      },
      editPlanSettings: {
        path: "/subcription/:id/plan/:idPlan",
        hideCountryNavbar: true,
        pathFile: "pages/subsriptions/plans/form",
      },
      addbanner: {
        path: "/appbanner/addbanner/overview",
        pathFile: "pages/Setting/banner/add",
        hideCountryNavbar: true
      },
      AddPromoCodeOverview: {
        path: "/promocode/addpromocode/overview",
        pathFile: "pages/Setting/PromoCode/Create",
        hideCountryNavbar: true
      },
      HoroscopeDailyCreate: {
        path: "/addhoroscopedaily/overview",
        pathFile: "pages/selfDiscover/horoscope/add",
        hideCountryNavbar: true
      },
      HoroscopeDailyEdit: {
        path: "/edithoroscopedaily/:id",
        hideCountryNavbar: true,
        pathFile: "pages/selfDiscover/horoscope/add",
      },
      HoroscopeDailyDetail: {
        path: "/detailhoroscopedaily/:id",
        hideCountryNavbar: true,
        pathFile: "pages/selfDiscover/horoscope",
      },
      BaziWeightCreate: {
        path: "/addbazidaily/overview/:lang",
        pathFile: "pages/selfDiscover/Ba-zi/edit",
        hideCountryNavbar: true,
      },
      LuckyItemsImport: {
        path: "luckitems/import",
        pathFile: "pages/selfDiscover/ImportLuckyItems/index",
      },
      Home: {
        path: "/",
      },
      AccessDenied: {
        path: "/accessDenied",
        pathFile: "pages/AccessDeniedPage",
      },
    },
  },
  dashboard: {
    name: "DASHBOARD",
    route: {
      DashboardOverview: {
        path: "/dashboard/overview",
        pathFile: "pages/dashboard/DashboardOverview",
        title: "Dashboard",
        auth: DashboardAuthorize.dashboard,
        sidebar: true,
        hideCountryNavbar: true,
      },
    },
  },
  userManagement: {
    name: "USER MANAGEMENT",
    route: {
      AccountOverview: {
        path: "/account/overview",
        pathFile: "pages/account/AccountOverview",
        title: "Accounts",
        auth: UserManagementAuthorize.account,
        sidebar: true,
      },
      CuratedProfileList: {
        path: "/account/curated-profile",
        pathFile: "pages/account/curatedDating/CuratedProfileListPage",
        title: "Curated Dating Profile List",
        auth: UserManagementAuthorize.curated,
        sidebar: true,
        hideCountryNavbar: true,
      },
      CuratedProfilePage: {
        path: "/account/curated-profile/:id",
        pathFile: "pages/account/curatedDating/CuratedProfilePage",
        title: "Curated Dating Profile",
        auth: UserManagementAuthorize.curated,
        hideCountryNavbar: true,
      },
      MatchingProfile: {
        path: "/account/matching-profile",
        pathFile: "pages/account/curatedDating/MatchingProfilePage",
        title: "Matching Profile",
        auth: UserManagementAuthorize.curated,
        hideCountryNavbar: true,
      },
      MatchResult: {
        path: "/account/match-result",
        pathFile: "pages/account/curatedDating/MatchResultPage",
        title: "Match Result",
        auth: UserManagementAuthorize.curated,
        hideCountryNavbar: true,
      },
      MatchingProfileDetail: {
        path: "/account/matching-profile-detail",
        pathFile: "pages/account/curatedDating/MatchingProfileDetailPage",
        title: "Matching Profile Detail",
        auth: UserManagementAuthorize.curated,
        hideCountryNavbar: true,
      },
      Message: {
        path: "/message",
        pathFile: "pages/message/MessagePage",
        sidebar: true,
        title: "Messages",
        auth: UserManagementAuthorize.message,
        hideCountryNavbar: true,
      },
      Notes: {
        path: "/message/notes",
        pathFile: "pages/message/NotesPage",
        title: "Notes",
        auth: UserManagementAuthorize.notes,
        hideCountryNavbar: true,
      },
      ProfileOverview: {
        path: "/profile/overview",
        pathFile: "pages/account/ProfileOverview",
        title: "Profiles",
        auth: UserManagementAuthorize.profile,
        sidebar: true,
      },
      DetailProfileOverview: {
        path: "/profile/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/detailProfile/index",
        auth: UserManagementAuthorize.profile,
      },
      EditProfileOverview: {
        path: "/profile/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/detailProfile/edit",
        sidebar: false,
        auth: UserManagementAuthorize.profile,
      },
      ChangeBirthDateOverview: {
        path: "/changebirthdate/overview",
        pathFile: "pages/account/ChangeBirthdateRequest",
        title: "Change Birthdate Request",
        auth: UserManagementAuthorize.changeBirthdateRequest,
        sidebar: true,
      },
      ProfileChangeRequestOverview: {
        path: "/profilechangerequest/overview",
        pathFile: "pages/account/ProfileChangeRequest",
        title: "Update Profile Request",
        auth: UserManagementAuthorize.changeBirthdateRequest,
        sidebar: true,
      },
      DetailProfileChangeRequestOverview: {
        path: "/profilechangerequest/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/detailProfileChange/index",
        sidebar: false,
        auth: UserManagementAuthorize.changeBirthdateRequest,
      },
      IdentityVerificationOverview: {
        path: "/identityverification/overview",
        pathFile: "pages/account/IdentityVerification",
        title: "Badge Verification",
        auth: UserManagementAuthorize.badgeVerification,
        sidebar: true,
      },
      DetailIdentityVerificationOverview: {
        path: "/identityverification/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/identity/detail",
        sidebar: false,
        auth: UserManagementAuthorize.badgeVerification,
      },
      updateIdentityVerificationOverview: {
        path: "/identityverification/update/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/identity/edit(s)",
        auth: UserManagementAuthorize.allBadgeVerification,
        sidebar: false,
      },
      EditIdentityVerificationOverview: {
        path: "/identityverification/edit/",
        pathFile: "pages/account/EditIdentityVerification",
        title: "All Data Badge Verification",
        auth: UserManagementAuthorize.allBadgeVerification,
        sidebar: true,
      },
      KYCOverview: {
        path: "/kyc/overview",
        pathFile: "pages/account/KYCOverview",
        title: "KYC",
        auth: UserManagementAuthorize.kyc,
        sidebar: true,
      },
      KYCDetail: {
        path: "/kyc/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/detailKYC/index",
        auth: UserManagementAuthorize.kyc,
        sidebar: false,
      },
      KYCEditDetail: {
        path: "/kyc/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/account/editKYC/detail/index",
        auth: UserManagementAuthorize.allDataKyc,
      },
      KYCEdit: {
        path: "/kyc/edit",
        pathFile: "pages/account/editKYC/index",
        title: "All Data KYC",
        auth: UserManagementAuthorize.allDataKyc,
        sidebar: true,
      },
      ReferralList: {
        path: "/referrallist/overview",
        pathFile: "pages/account/ReferralList",
        title: "Referral Profile List",
        auth: UserManagementAuthorize.referralProfile,
        sidebar: true,
      },
      ProfileTag: {
        path: "/profiletag/overview",
        pathFile: "pages/account/ProfileTag",
        title: "Profile Tag",
        auth: UserManagementAuthorize.profileTag,
        sidebar: true,
        hideCountryNavbar: true,
      },
      CreateProfileTag: {
        path: "/profiletag/create/overview",
        pathFile: "pages/account/profileTags/add",
        auth: UserManagementAuthorize.profileTag,
        sidebar: false,
        hideCountryNavbar: true,
      },
      DetailProfileTag: {
        path: "/profiletag/detail/:id",
        pathFile: "pages/account/profileTags/index",
        auth: UserManagementAuthorize.profileTag,
        sidebar: false,
        hideCountryNavbar: true,
      },
      EditProfileTag: {
        path: "/profiletag/edit/:id",
        pathFile: "pages/account/profileTags/edit",
        auth: UserManagementAuthorize.profileTag,
        sidebar: false,
        hideCountryNavbar: true,
      },
      BillionaireKeywordSettings: {
        path: "/BillionareKeyword/Settings",
        pathFile: "pages/account/BillionaireKeywordSettings",
        title: "Billionaire Keyword Settings",
        auth: UserManagementAuthorize.billionaireKeywordSettings,
        sidebar: true,
        hideCountryNavbar: true,
      },
      CategorySelectionYouHost: {
        path: "/categoryselectionyouhost/overview",
        pathFile: "pages/contentManagement/CategorySelectorYouHost",
        title: "Category Selection YOUHosts",
        auth: UserManagementAuthorize.categorySelectionYouHost,
        hideCountryNavbar: true,
        sidebar: true,
      },
      CreateCategorySelectionYouHost: {
        path: "/categoryselectionyouhost/create/overview",
        pathFile: "pages/contentManagement/selectorCategoryYouHost/create",
        auth: UserManagementAuthorize.categorySelectionYouHost,
        hideCountryNavbar: true,
        sidebar: false,
      },
      DetailCategorySelectionYouHost: {
        path: "/categoryselectionyouhost/detail/:id",
        pathFile: "pages/contentManagement/selectorCategoryYouHost/index",
        auth: UserManagementAuthorize.categorySelectionYouHost,
        hideCountryNavbar: true,
        sidebar: false,
      },
      EditCategorySelectionYouHost: {
        path: "/categoryselectionyouhost/edit/:id",
        pathFile: "pages/contentManagement/selectorCategoryYouHost/edit",
        auth: UserManagementAuthorize.categorySelectionYouHost,
        sidebar: false,
        hideCountryNavbar: true,
      },
      EditRoleOverview: {
        path: "/role/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/MasterAdmin/form/edit",
        sidebar: false,
        auth: MasterAdminAuthorize.role,
      },
      AddRoleOverview: {
        path: "/role/addrole/overview",
        auth: MasterAdminAuthorize.role,
        pathFile: "pages/MasterAdmin/form/add",
        hideCountryNavbar: true
      },
      AddUserOverview: {
        path: "/user/adduser/overview",
        pathFile: "pages/MasterAdmin/user/AddUserPage",
        sidebar: false,
        auth: MasterAdminAuthorize.admin,
      },
    },
  },
  contentManagement: {
    name: "CONTENT MANAGEMENT",
    route: {
      UtmHistory: {
        path: "/utmhistory/overview",
        pathFile: "pages/contentManagement/UtmHistory",
        title: "UTM Campaign History",
        auth: ContentManagementAuthorize.utmHistory,
        sidebar: true,
      },
      UtmHistorySignUp: {
        path: "/utmhistorysignup/overview",
        pathFile: "pages/contentManagement/UtmHistorySignups",
        title: "UTM Signups",
        auth: ContentManagementAuthorize.utmSignup,
        sidebar: true,
      },
    },
  },
  reportManagement: {
    name: "REPORT MANAGEMENT",
    route: {
      ReportedContent: {
        path: "/reportedcontent/overview",
        pathFile: "pages/contentManagement/ReportedContent",
        title: "Reported Contents",
        auth: ContentManagementAuthorize.reportedContent,
        sidebar: true,
      },
      DetailReportedProfileOverview: {
        path: "/reported-content/profile/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.reportedContent,
        pathFile: "pages/account/detailReportedProfile/index",
      },
    },
  },
  servicesContentManagement: {
    name: "SERVICES CONTENT MANAGEMENT",
    route: {
      ProductServicesOverview: {
        path: "/productservices/overview",
        pathFile: "pages/contentManagement/Services",
        title: "Services/Experiences",
        auth: ContentManagementAuthorize.servicesExperienceList,
        sidebar: true,
      },
      DetailProductServicesOverview: {
        path: "/productservices/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailServices/index",
        auth: ContentManagementAuthorize.servicesExperienceList,
        sidebar: false,
      },
      EditProductServicesOverview: {
        path: "/productservices/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailServices/edit",
        auth: ContentManagementAuthorize.servicesExperienceList,
        sidebar: false,
      },
      Discoveroverview: {
        path: "/discover/overview",
        pathFile: "pages/contentManagement/DiscoverVideo",
        title: "Discover Experience",
        auth: ContentManagementAuthorize.discoverExperience,
        sidebar: true,
      },
      CreateDiscoverOverview: {
        path: "/discover/create/overview",
        pathFile: "pages/contentManagement/detailDiscover/create",
        auth: ContentManagementAuthorize.discoverExperience,
        sidebar: false,
      },
      DetailDiscoverOverview: {
        path: "/discover/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailDiscover/index",
        auth: ContentManagementAuthorize.discoverExperience,
        sidebar: false,
      },
      EditDiscoverOverview: {
        path: "/discover/edit/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailDiscover/edit",
        auth: ContentManagementAuthorize.discoverExperience,
        sidebar: false,
      },
      ViewSubmissionsOverview: {
        path: "/viewsubmissions/overview",
        pathFile: "pages/contentManagement/ViewSubmission",
        title: "Discover Submissions",
        auth: ContentManagementAuthorize.discoverExperience,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailViewSubmissionsOverview: {
        path: "/viewsubmissions/detail/:id",
        pathFile: "pages/contentManagement/detailSubmission/index",
        auth: ContentManagementAuthorize.discoverExperience,
        sidebar: false,
        hideCountryNavbar: true,
      },
      FeatureServiceOverview: {
        path: "/featureservice/overview",
        pathFile: "pages/contentManagement/FeatureExp",
        title: "Featured Experiences",
        auth: ContentManagementAuthorize.featureExperience,
        sidebar: true,
      },
      AddFeatureServiceOverview: {
        path: "/featureservice/addfeatureservice/overview",
        pathFile: "pages/contentManagement/featureExperience",
        auth: ContentManagementAuthorize.featureExperience,
        sidebar: false,
      },
      FeatureDiscoverExpOverview: {
        path: "/featureDiscoverExp/overview",
        pathFile: "pages/contentManagement/FeatureDiscoverExp",
        title: "Featured Discover Experiences",
        auth: ContentManagementAuthorize.featureExperience,
        sidebar: true,
      },
      AddFeatureDiscoverExpOverview: {
        path: "/featurecommunity/addfeatureDiscoverExp/overview",
        pathFile: "pages/contentManagement/featureDiscoverExps",
        auth: ContentManagementAuthorize.featureExperience,
        sidebar: false,
      },
      CategorySelectionExperience: {
        path: "/categoryselectionexperience/overview",
        pathFile: "pages/contentManagement/CategorySelectorExp",
        title: "Category Selection Experience",
        auth: ContentManagementAuthorize.categorySelectionExperience,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailCategorySelectionExperience: {
        path: "/categoryselectionexperience/detail/:id",
        pathFile: "pages/contentManagement/selectorCategoryExp/index",
        title: "Category Selection Experience",
        auth: ContentManagementAuthorize.categorySelectionExperience,
        sidebar: false,
        hideCountryNavbar: true,
      },
      EditCategorySelectionExperience: {
        path: "/categoryselectionexperience/edit/:id",
        pathFile: "pages/contentManagement/selectorCategoryExp/edit",
        title: "Category Selection Experience",
        auth: ContentManagementAuthorize.categorySelectionExperience,
        sidebar: false,
        hideCountryNavbar: true,
      },
    },
  },
  matchingContentManagement: {
    name: "MATCHING CONTENT MANAGEMENT",
    route: {
      MatchedPeople: {
        path: "/matchedpeople/overview",
        pathFile: "pages/matchingContentManagement/matchedPeople",
        title: "Matched People",
        auth: MatchContentManagementAuthorize.matchedPeople,
        sidebar: true,
        hideCountryNavbar: true,
      },
    },
  },
  meetsContentManagement: {
    name: "MEETS CONTENT MANAGEMENT",
    route: {
      MeetHostsOverview: {
        path: "/meet/hosts",
        pathFile: "pages/meetsContentManagement/MeetHosts",
        title: "Meet Hosts",
        auth: MeetsContentManagementAuthorize.meetHostsOverview,
        sidebar: true,
      },
      MeetList: {
        path: "/meet/all",
        pathFile: "pages/meetsContentManagement/MeetAll",
        title: "Meet List",
        auth: MeetsContentManagementAuthorize.meetList,
        sidebar: true,
      },
      MeetDetail: {
        path: "/meet/detail/:id",
        pathFile: "pages/meetsContentManagement/MeetDetail",
        title: "Meet Detail",
        auth: MeetsContentManagementAuthorize.meetDetail,
        sidebar: false,
        hideCountryNavbar: true
      },
      CreateMeet: {
        path: "/meet/create",
        pathFile: "pages/meetsContentManagement/MeetDetail",
        title: "Meet Detail",
        auth: MeetsContentManagementAuthorize.meetDetail,
        sidebar: false,
        hideCountryNavbar: true
      },
      MeetBookList: {
        path: "/meet/book-list",
        pathFile: "pages/meetsContentManagement/MeetBookList",
        title: "Meet Booking History",
        auth: MeetsContentManagementAuthorize.meetList,
        sidebar: true,
        hideCountryNavbar: true
      },
    }
  },
  miniEventsContentManagement: {
    name: "MINI EVENTS CONTENT MANAGEMENT",
    route: {
      MiniEventOverview: {
        path: "/mini-event/overview",
        pathFile: "pages/MiniEventsContentManagement/MiniEventOverview",
        title: "Mini Events",
        // auth: ContentManagementAuthorize.miniEvent,
        auth: MatchContentManagementAuthorize.matchedPeople,
        sidebar: true,
      },
      MiniEventDetail: {
        path: "/mini-event/detail/:id",
        hideCountryNavbar: true,
        // auth: ContentManagementAuthorize.miniEvent,
        auth: MatchContentManagementAuthorize.matchedPeople,
        pathFile: "pages/MiniEventsContentManagement/MiniEventDetail",
      },
      MiniEventDispute: {
        path: "/mini-event/dispute",
        pathFile: "pages/MiniEventsContentManagement/MiniEventDispute",
        title: "Mini Event Disputes",
        // auth: ContentManagementAuthorize.miniEventDispute,
        auth: MatchContentManagementAuthorize.matchedPeople,
        sidebar: true,
      },
    },
  },
  eventsContentManagement: {
    name: "EVENTS CONTENT MANAGEMENT",
    route: {
      EventListOverview: {
        path: "/eventlist/overview",
        pathFile: "pages/contentManagement/Event",
        title: "Event",
        auth: ContentManagementAuthorize.events,
        sidebar: true,
      },
      DetailEventListOverview: {
        path: "/eventlist/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.events,
        pathFile: "pages/contentManagement/detailEvent/index",
      },
      EditEventListOverview: {
        path: "/eventlist/edit/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.events,
        pathFile: "pages/contentManagement/detailEvent/edit",
      },
      DetailReportedEventListOverview: {
        path: "reported-content/event/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.events,
        pathFile: "pages/contentManagement/detailReportedEvent",
      },
      EventTicketOverview: {
        path: "/eventticket/overview",
        pathFile: "pages/contentManagement/EventTicket",
        title: "Event Ticket",
        auth: ContentManagementAuthorize.eventTicket,
        sidebar: true,
      },
      EventAttendeesOverview: {
        path: "/eventattendees/overview",
        pathFile: "pages/contentManagement/EventAttendees",
        title: "Event Attendees",
        auth: ContentManagementAuthorize.eventAttendees,
        sidebar: true,
      },
      FeatureEventOverview: {
        path: "/featureevent/overview",
        pathFile: "pages/contentManagement/FeatureEvent",
        title: "Featured Events",
        auth: ContentManagementAuthorize.featureEvent,
        sidebar: true,
      },
      CategorySelectionEvents: {
        path: "/categoryselectionevents/overview",
        pathFile: "pages/contentManagement/CategorySelectorEvents",
        title: "Category Selection Events",
        auth: ContentManagementAuthorize.categorySelectionEvents,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailCategorySelectionEvents: {
        path: "/categoryselectionevents/detail/:id",
        auth: ContentManagementAuthorize.categorySelectionEvents,
        pathFile: "pages/contentManagement/selectorCategoryEvent/index",
        hideCountryNavbar: true,
      },
      EditCategorySelectionEvents: {
        path: "/categoryselectionevents/edit/:id",
        auth: ContentManagementAuthorize.categorySelectionEvents,
        pathFile: "pages/contentManagement/selectorCategoryEvent/edit",
        hideCountryNavbar: true,
      },
    },
  },
  groupsContentManagement: {
    name: "GROUPS CONTENT MANAGEMENT",
    route: {
      GroupListOverview: {
        path: "/grouplist/overview",
        pathFile: "pages/contentManagement/Group",
        title: "Groups",
        auth: ContentManagementAuthorize.groupList,
        sidebar: true,
      },
      DetailGroupListOverview: {
        path: "/grouplist/detail/:id",
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/detailGroup/index",
        auth: ContentManagementAuthorize.groupList,
      },
      featureGroupOverview: {
        title: "Featured Groups",
        path: "/featuregroup/overview",
        auth: ContentManagementAuthorize.featureGroup,
        pathFile: "pages/contentManagement/FeatureGroups",
        sidebar: true,
      },
      AddFeatureGroupOverview: {
        path: "/featuregroup/addfeaturegroup/overview",
        auth: ContentManagementAuthorize.featureGroup,
        pathFile: "pages/contentManagement/featureGroups/index",
      },
    },
  },
  placesContentManagement: {
    name: "PLACES CONTENT MANAGEMENT",
    route: {
      PlacesOverview: {
        path: "/places/overview",
        pathFile: "pages/contentManagement/Places",
        title: "Places",
        auth: ContentManagementAuthorize.places,
        hideCountryNavbar: true,
        sidebar: true,
      },
      CategorySelectionPlace: {
        path: "/categoryselectionplace/overview",
        pathFile: "pages/contentManagement/CategorySelectorPlaces",
        title: "Category Selection Places",
        auth: ContentManagementAuthorize.categorySelectionPlaces,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailCategorySelectionPlace: {
        path: "/categoryselectionplace/detail/:id",
        auth: ContentManagementAuthorize.categorySelectionPlaces,
        pathFile: "pages/contentManagement/selectorCategoryPlace/index",
        hideCountryNavbar: true,
      },
      EditCategorySelectionPlace: {
        path: "/categoryselectionplace/edit/:id",
        auth: ContentManagementAuthorize.categorySelectionPlaces,
        pathFile: "pages/contentManagement/selectorCategoryPlace/edit",
        hideCountryNavbar: true,
      },
      featurePlaceOverview: {
        title: "Featured Places",
        path: "/featureplace/overview",
        pathFile: "pages/contentManagement/FeaturePlaces",
        auth: ContentManagementAuthorize.featurePlaces,
        hideCountryNavbar: true,
        sidebar: true,
      },
      AddFeaturePlaceOverview: {
        path: "/featureplace/addfeatureplace/overview",
        auth: ContentManagementAuthorize.featurePlaces,
        hideCountryNavbar: true,
        pathFile: "pages/contentManagement/featurePlaces/index",
      },
    },
  },
  comunitiesContentManagement: {
    name: "COMMUNITIES CONTENT MANAGEMENT",
    route: {
      FeatureComunityOverview: {
        path: "/featurecommunity/overview",
        pathFile: "pages/contentManagement/featureComunity",
        title: "Featured Comunities",
        auth: ContentManagementAuthorize.featureCommunities,
        sidebar: true,
      },
      ArticleCategorie: {
        path: "articlecategory/overview",
        pathFile: "pages/contentManagement/ArticleCategory",
        title: "Articles Category",
        auth: ContentManagementAuthorize.articleCategory,
        sidebar: true,
        hideCountryNavbar: true,
      },
      ComunityOverview: {
        path: "/community/overview",
        pathFile: "pages/contentManagement/Comunity",
        title: "Communities",
        auth: ContentManagementAuthorize.community,
        sidebar: true,
      },
      DetailComunityOverview: {
        path: "/community/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.community,
        pathFile: "pages/contentManagement/detailComunity/index",
      },
      EditComunityOverview: {
        path: "/community/edit/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.community,
        pathFile: "pages/contentManagement/detailComunity/edit",
      },
      ComunityContent: {
        path: "/communitycontent/overview",
        pathFile: "pages/contentManagement/ContentComunity",
        title: "Content of All Communities",
        auth: ContentManagementAuthorize.contentAllCommunities,
        sidebar: true,
      },
      ContentProfileOverview: {
        path: "/contentProfile/overview",
        pathFile: "pages/account/ContentProfileOverview",
        title: "Content of All Profile Post",
        auth: ContentManagementAuthorize.contentAllProfilePost,
        sidebar: true,
      },
      DetailPostComunity: {
        path: "/post/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.communityContents,
        pathFile: "pages/contentManagement/detailPost/index",
      },
    },
  },
  wishlistContentManagement: {
    name: "WISHLIST CONTENT MANAGEMENT",
    route: {
      WishlistUser: {
        path: "/wishlistuser/overview",
        pathFile: "pages/contentManagement/WishlistUser",
        title: "Wishlist of User",
        auth: ContentManagementAuthorize.wishlistUser,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailWishlistUser: {
        path: "/wishlist/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.wishlistUser,
        pathFile: "pages/contentManagement/detailWishlist/index",
      },
      WishlistService: {
        path: "/wishlistservice/overview",
        pathFile: "pages/contentManagement/WishlistService",
        title: "Experience Wishlists",
        auth: ContentManagementAuthorize.wishlistExp,
        sidebar: true,
        hideCountryNavbar: true,
      },
      WishlistEvent: {
        path: "/wishlistevent/overview",
        pathFile: "pages/contentManagement/WishlistEvent",
        title: "Event Wishlists",
        auth: ContentManagementAuthorize.wishlistEvent,
        sidebar: true,
        hideCountryNavbar: true,
      },
      WishlistGroup: {
        path: "/wishlistgroup/overview",
        pathFile: "pages/contentManagement/WishlistPaidGroups",
        title: "Groups Wishlists",
        auth: ContentManagementAuthorize.wishlistGroup,
        sidebar: true,
        hideCountryNavbar: true,
      },
      WishlistPlace: {
        path: "/wishlistplace/overview",
        pathFile: "pages/contentManagement/WishlistPlaces",
        title: "Places Wishlists",
        auth: ContentManagementAuthorize.wishlistPlace,
        sidebar: true,
        hideCountryNavbar: true,
      },
      BoughtWishlist: {
        path: "/boughtwishlist/overview",
        pathFile: "pages/contentManagement/BoughtWishlist",
        title: "Bought Wishlists",
        auth: ContentManagementAuthorize.wishlistBought,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailTransactionHistory: {
        path: "/transactionhistory/detail/:id",
        hideCountryNavbar: true,
        auth: ContentManagementAuthorize.wishlistBought,
        pathFile: "pages/Finance/DetailTransactionHistory/index",
      },
      // DetailTransactionHistoryNew: {
      //   path: "/transactionhistory/new-detail/:id",
      //   hideCountryNavbar: true,
      //   auth: ContentManagementAuthorize.wishlistBought,
      //   pathFile: "pages/Finance/DetailTransactionHistory/DetailTransactionNew",
      // },
    },
  },
  financeManagement: {
    name: "FINANCE MANAGEMENT",
    route: {
      PaymentRequest: {
        path: "/paymentrequest/overview",
        pathFile: "pages/Finance/PaymentRequest",
        title: "Payment Request",
        auth: FinanceManagementAuthorize.paymentRequest,
        sidebar: true,
      },
      YouPlusOverview: {
        path: "/youplus/overview",
        pathFile: "pages/Finance/YouPlusSubscripbed",
        title: "YOU + Subscribed User",
        auth: FinanceManagementAuthorize.youPlusSubscriptionUser,
        sidebar: true,
      },
      WithdrawalRequest: {
        path: "/withdrawalrequest/overview",
        pathFile: "pages/Finance/WithdrawlRequest",
        title: "Withdrawal Request",
        auth: FinanceManagementAuthorize.withdrawalRequest,
        sidebar: true,
      },
      // NewTransactionHistory: {
      //   path: "/transactionhistoryNew/overview",
      //   pathFile: "pages/Finance/TransactionHistoryNew",
      //   title: "Transaction History (NEW)",
      //   auth: FinanceManagementAuthorize.transaction,
      //   sidebar: true,
      // },
      TransactionHistory: {
        path: "/transactionhistory/overview",
        pathFile: "pages/Finance/TransactionHistory",
        title: "Transaction History",
        auth: FinanceManagementAuthorize.transaction,
        sidebar: true,
      },
      DebitOverview: {
        path: "/debit/overview",
        pathFile: "pages/Finance/DebitAssets",
        title: "Debit Credit Assets",
        auth: FinanceManagementAuthorize.debitCreditAssets,
        sidebar: true,
      },
      LiveUserOverview: {
        path: "/liveuser/overview",
        pathFile: "pages/Finance/LiveUserAssets",
        title: "Live Users Asset Holding",
        auth: FinanceManagementAuthorize.assetHoldingLive,
        sidebar: true,
      },
      SnapshootOverview: {
        path: "/snapshoot/overview",
        pathFile: "pages/Finance/SnapshootAsset",
        title: "Snapshot of Daily Assets Holding",
        auth: FinanceManagementAuthorize.assetHoldingDaily,
        sidebar: true,
        hideCountryNavbar: true,
      },
      ViewAllFeesOverview: {
        path: "/viewallfees/overview",
        pathFile: "pages/Finance/ViewAllFees",
        title: "View all Fees Earned",
        auth: FinanceManagementAuthorize.viewAllFeesEarned,
        sidebar: true,
      },
      TotalFeesDayOverview: {
        path: "/totalfeesday/overview",
        pathFile: "pages/Finance/TotalFeesPerDay",
        title: "Total Fees Earned Per Day",
        auth: FinanceManagementAuthorize.totalFeesEarnedPerDay,
        sidebar: true,
        hideCountryNavbar: true,
      },
      LockedAssetsOverview: {
        path: "/lockedassets/overview",
        pathFile: "pages/Finance/LockedAssets",
        title: "Locked Assets",
        auth: FinanceManagementAuthorize.lockedAssets,
        sidebar: true,
      },
    },
  },
  selfDiscovery: {
    name: "SELF DISCOVERY",
    route: {
      InterestCategories: {
        path: "self-discovery/interest-categories",
        pathFile: "pages/selfDiscover/InterestCategories",
        title: "Interest Categories",
        auth: SelfDiscoveryAuthorize.zodiacDaily,
        sidebar: true,
        hideCountryNavbar: true,
      },
      ZodiacDailyOverview: {
        path: "zodiac/overview",
        pathFile: "pages/selfDiscover/ZodiacDaily",
        title: "Daily Zodiac",
        auth: SelfDiscoveryAuthorize.zodiacDaily,
        sidebar: true,
        hideCountryNavbar: true,
      },
      ZodiacDailyDetail: {
        path: "/detailzodiacdaily/:id",
        hideCountryNavbar: true,
        auth: SelfDiscoveryAuthorize.zodiacDaily,
        pathFile: "pages/selfDiscover/zodiac/index",
      },
      ZodiacDailyEdit: {
        path: "/editzodiacdaily/:id",
        hideCountryNavbar: true,
        auth: SelfDiscoveryAuthorize.zodiacDaily,
        pathFile: "pages/selfDiscover/zodiac/edit",
      },
      HoroscopeDailyOverview: {
        path: "horoscope/overview",
        pathFile: "pages/selfDiscover/HoroscopeDaily2",
        title: "Daily Horoscope",
        auth: SelfDiscoveryAuthorize.horoscopeDaily,
        sidebar: true,
        hideCountryNavbar: true,
      },
      HoroscopeTitleOverview: {
        path: "horoscopetitle/overview",
        pathFile: "pages/selfDiscover/horoscope/title",
        title: "Horoscope Titles",
        auth: SelfDiscoveryAuthorize.horoscopeDailyTitle,
        sidebar: true,
        hideCountryNavbar: true,
      },
      BaziWeightOverview: {
        path: "bazidaily/overview",
        pathFile: "pages/selfDiscover/BaziDaily",
        title: "Ba Zi",
        auth: SelfDiscoveryAuthorize.baziBone,
        sidebar: true,
        hideCountryNavbar: true,
      },
      BaziTitlesOverview: {
        path: "bazititles/overview",
        pathFile: "pages/selfDiscover/Ba-zi/title",
        title: "Ba Zi Titles",
        auth: SelfDiscoveryAuthorize.baziBoneTitle,
        sidebar: true,
        hideCountryNavbar: true,
      },
      BaziWeightEdit: {
        path: "/editbazidaily/:id/:lang",
        hideCountryNavbar: true,
        auth: SelfDiscoveryAuthorize.baziBone,
        pathFile: "pages/selfDiscover/Ba-zi/edit",
      },
      LuckyCategoriesOverview: {
        path: "luckycategories/overview",
        pathFile: "pages/selfDiscover/LuckyCategories",
        title: "Lucky Categories",
        auth: SelfDiscoveryAuthorize.luckCategory,
        sidebar: true,
        hideCountryNavbar: true,
      },
      LuckyItemsOverview: {
        path: "luckitems/overview",
        pathFile: "pages/selfDiscover/LuckyItems",
        title: "Lucky Items",
        auth: SelfDiscoveryAuthorize.luckItem,
        sidebar: true,
        hideCountryNavbar: true,
      },
      ConsultationsCategories: {
        path: "self-discovery/consultation-categories",
        pathFile: "pages/consultationsCategories",
        title: "Consultation Categories",
        auth: SelfDiscoveryAuthorize.consultationsCategories,
        sidebar: true,
        hideCountryNavbar: true,
      },
      MetaphysicsPracticesCategory: {
        path: "self-discovery/metaphysic-practice-categories",
        pathFile: "pages/metaphysicsPracticesCategories",
        title: "Metaphysic Practices Categories",
        auth: SelfDiscoveryAuthorize.cetaphysicsPracticesCategory,
        sidebar: true,
        hideCountryNavbar: true,
      },
      MetaphysicistsProfile: {
        path: "self-discovery/metaphysicist-profiles",
        pathFile: "pages/metaphysicistsProfiles",
        title: "Metaphysicist Profiles",
        auth: SelfDiscoveryAuthorize.cetaphysicistsProfile,
        sidebar: true,
        hideCountryNavbar: true,
      },
      MetaphysicistsProfileEdit: {
        path: "self-discovery/metaphysicist-profiles/edit/:id",
        pathFile: "pages/metaphysicistsProfiles/edit",
        title: "Metaphysicist Profiles",
        auth: SelfDiscoveryAuthorize.cetaphysicistsProfile,
        sidebar: false,
        hideCountryNavbar: true,
      },
      
    },
  },
  masterAdmin: {
    name: "MASTER ADMIN",
    route: {
      RoleOverview: {
        path: "/role/overview",
        pathFile: "pages/MasterAdmin/RoleOverview",
        title: "Role",
        auth: MasterAdminAuthorize.role,
        sidebar: true,
        hideCountryNavbar: true,
      },
      UserOverview: {
        path: "/user/overview",
        pathFile: "pages/MasterAdmin/UserAdmin",
        title: "User Admin",
        auth: MasterAdminAuthorize.admin,
        sidebar: true,
        hideCountryNavbar: true,
      },
      EditUserOverview: {
        path: "/user/edituser/:id",
        hideCountryNavbar: true,
        auth: MasterAdminAuthorize.admin,
        pathFile: "pages/MasterAdmin/user/AddUserPage",
      },
    },
  },
  newsletter: {
    name: "NEWSLETTER",
    route: {
      NewsLetterOverview: {
        path: "newsletter/overview",
        pathFile: "pages/newsletter/NewsLetterOverview",
        title: "Subscriber List",
        auth: GlobalSettingAuthorize.allExchangeRates,
        sidebar: true,
        hideCountryNavbar: true,
      },
      NewsLetterSentLogDetail: {
        path: "newsletter/sentlog/detail/:id",
        pathFile: "pages/newsletter/NewSletterSentLogDetail",
        auth: GlobalSettingAuthorize.allExchangeRates,
        hideCountryNavbar: true,
      },
      NewsLetterSentLogOverview: {
        path: "newsletter/sentlog/overview",
        pathFile: "pages/newsletter/NewsLetterSentLogOverview",
        title: "Sent Log",
        auth: GlobalSettingAuthorize.allExchangeRates,
        sidebar: true,
        hideCountryNavbar: true,
      },
      NewsletterSetting: {
        path: "newsletter/setting",
        pathFile: "pages/newsletter/NewsLetterSetting",
        title: "Daily Newsletter Settings",
        auth: GlobalSettingAuthorize.allExchangeRates,
        sidebar: true,
        hideCountryNavbar: true,
      },
    },
  },
  globalSettings: {
    name: "GLOBAL SETTINGS",
    route: {
      globalSettings: {
        path: "/globalSettings/overview",
        pathFile: "pages/globalSettings/index",
        title: "Global Settings",
        auth: GlobalSettingAuthorize.globalFeeSetting,
        sidebar: true,
        hideCountryNavbar: true,
      },
      versioningSettings: {
        path: "/versioningSettings/overview",
        pathFile: "pages/Setting/VersioningSettings",
        title: "Versioning Settings",
        auth: GlobalSettingAuthorize.versionSetting,
        sidebar: true,
        hideCountryNavbar: true,
      },
      profileExemptionBuyerFees: {
        path: "/profileExemptionBuyerFees/overview",
        pathFile: "pages/Setting/ProfileExecption",
        title: "Exemption Fees",
        auth: GlobalSettingAuthorize.exemptionFees,
        sidebar: true,
        hideCountryNavbar: true,
      },
      offensiveWords: {
        path: "/offensiveWords/overview",
        pathFile: "pages/Setting/OffensiveWords",
        title: "Offensive Words Settings",
        auth: GlobalSettingAuthorize.offensiveWords,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DailyFxRate: {
        path: "/dailyfx/overview",
        pathFile: "pages/Setting/DailyFxRate",
        title: "Daily FX Rates Snapshot",
        auth: GlobalSettingAuthorize.dailyFxRatesSnapshoot,
        sidebar: true,
        hideCountryNavbar: true,
      },
      exchangeRate: {
        path: "/exchangeRate/overview",
        pathFile: "pages/Setting/ExchangeRate",
        title: "All Exchange Rates",
        auth: GlobalSettingAuthorize.allExchangeRates,
        sidebar: true,
        hideCountryNavbar: true,
      },
      editexchangeRate: {
        path: "/exchangeRate/editexchangeRate/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.allExchangeRates,
        pathFile: "pages/Setting/exchange/edit",
      },
      subcription: {
        path: "/subcriptions",
        pathFile: "pages/subsriptions/index",
        title: "Subscription Settings",
        auth: GlobalSettingAuthorize.subscriptionSetting,
        sidebar: true,
        hideCountryNavbar: true,
      },
      detailSubcription: {
        path: "/subcription/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.subscriptionSetting,
        pathFile: "pages/subsriptions/manageSubcription",
      },
      MatchingSetting: {
        path: "/matching-setting/overview",
        pathFile: "pages/Setting/MatchingSetting",
        title: "Matching Setting",
        auth: GlobalSettingAuthorize.promoCode,
        sidebar: true,
        hideCountryNavbar: true,
      },
      keywordSettings: {
        path: "/keywordSettings/overview",
        pathFile: "pages/Setting/KeywordSettings",
        title: "Keywords Settings",
        auth: GlobalSettingAuthorize.keywordsSetting,
        sidebar: true,
        hideCountryNavbar: true,
      },
      banneroverview: {
        path: "/appbanner/overview",
        pathFile: "pages/Setting/Banner",
        title: "In App Banner",
        auth: GlobalSettingAuthorize.appBanner,
        sidebar: true,
        hideCountryNavbar: true,
      },
      editbanner: {
        path: "/appbanner/editbanner/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.appBanner,
        pathFile: "pages/Setting/banner/edit",
      },
      PromoCodeOverview: {
        path: "/promocode/overview",
        pathFile: "pages/Setting/PromoCode",
        title: "Promo Code",
        auth: GlobalSettingAuthorize.promoCode,
        sidebar: true,
        hideCountryNavbar: true,
      },
      DetailPromoCodeOverview: {
        path: "/promocode/detail/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.promoCode,
        pathFile: "pages/Setting/PromoCode/index",
      },
      EditPromoCodeOverview: {
        path: "/promocode/edit/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.promoCode,
        pathFile: "pages/Setting/PromoCode/Edit",
      },
      announcementOverview: {
        path: "/announcement/overview",
        title: "Announcement (Push Notification)",
        sidebar: true,
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/Announcement",
        hideCountryNavbar: true,
      },
      addannouncement: {
        path: "/announcement/addannouncement/overview",
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/announcement/add",
        hideCountryNavbar: true
      },
      editannouncement: {
        path: "/announcement/editannouncement/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/announcement/edit",
      },
      detailannouncement: {
        path: "/announcement/detailannouncement/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/announcement/index",
      },
      announcementAlert: {
        path: "/announcementAlert/overview",
        title: "Announcement (Alert Notification)",
        sidebar: true,
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/AnnouncementAlert",
        hideCountryNavbar: true,
      },
      addAnnouncementAlert: {
        path: "/announcementAlert/addAnnouncementAlert/overview",
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/announcementAlert/add",
        hideCountryNavbar: true
      },
      editAnnouncementAlert: {
        path: "/announcementAlert/editAnnouncementAlert/:id",
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/announcementAlert/add",
        hideCountryNavbar: true
      },
      detailAnnouncementAlert: {
        path: "/announcementAlert/detailAnnouncementAlert/:id",
        hideCountryNavbar: true,
        auth: GlobalSettingAuthorize.anncouncement,
        pathFile: "pages/Setting/announcementAlert/index",
      },
      schedulerStatus: {
        path: "/global/scheduler-status",
        pathFile: "pages/globalSettings/SchedulerStatus",
        hideCountryNavbar: true,
      },
    },
  },
  logs: {
    name: "LOGS",
    route: {
      logHistory: {
        path: "/logHistory/overview",
        pathFile: "pages/Log/index",
        title: "Log History",
        auth: LogsAuthorize.log,
        sidebar: true,
        hideCountryNavbar: true,
      },
    },
  },
};

export const menuPage = (isSuperAdmin, autorizes) => {
  return routeList().reduce((prev, curr) => {
    if (curr.sidebar)
      prev = [
        ...prev,
        {
          ...curr,
          hidden: !isSuperAdmin || autorizes?.includes(curr?.auth?.value),
        },
      ];
    return prev;
  }, []);
};

/**
 *
 * @param only will return the key that listed
 * @param except will return the key not listed
 *
 * @returns [route]
 */
export const routeList = (params = { only: null, except: null }) => {
  const { only, except } = params;
  const data = Object.values(route)
    .reduce((prev, curr) => {
      prev = [
        ...prev,
        ...Object.entries(curr.route).map(([key, value]) => {
          return { key, category: curr.name, ...value };
        }),
      ];
      return prev;
    }, [])
    .filter((value) => {
      if (only) return only?.includes(value.key);
      if (except) return !except?.includes(value.key);
      return true;
    });
  return data;
};

/**
 *
 * @param only will return the key that listed
 * @param except will return the key not listed
 *
 * @returns {[key] : route}
 */
export const routeMap = (params = { only: null, except: null }) => {
  return routeList({ only: params.only, except: params.except }).reduce(
    (prev, curr) => {
      const data = {
        key: curr.key,
        ...curr,
      };
      prev[curr.key] = data;
      return prev;
    },
    {}
  );
};

/**
 * @returns {[key] : string}
 */
export const routeKey = () => {
  const key = routeList().reduce((prev, curr) => {
    prev[curr.key] = curr.key;
    return prev;
  }, {});
  return key;
};

/**
 * YOOO WASSAP, cah bagoes here
 * jadi gini ges, ini buat replace route ya sapa tau nanti butuh buat ngganti isi path yang ada anunya
 * 
 * Fungsi ini mengambil rute yang mungkin memiliki placeholder dalam format `:key`
 * dan menggantinya dengan nilai yang sesuai dari array data.
 *
 * @param {string} route - Rute yang akan diubah, yang mungkin mengandung placeholder.
 * @param {Array<{key: string, value:string}>} [data=[]] - Array objek yang berisi pasangan key-value untuk menggantikan placeholder.
 * @param {string} data[].key - Kunci yang digunakan sebagai placeholder dalam rute.
 * @param {string} data[].value - Nilai yang akan menggantikan placeholder yang sesuai.
 * 
 * @returns {string} Rute yang telah dimodifikasi dengan placeholder yang diganti dengan nilai dari data.
 * 
 * @example
 * const route = '/user/:userId/profile';
 * const data = [{ key: 'userId', value: '12345' }];
 * const result = routeReplacer(route, data);
 * console.log(result); // '/user/12345/profile'
 */
export const routeReplacer = (route, data = []) => {
  let result = route;
  Array.from(data).forEach((_, index) => {
    result = result.replace(`:${data[index].key}`, data[index].value);
  });
  return result;
};
